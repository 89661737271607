@import url("https://use.typekit.net/evg8bhr.css");

html {
  background: white;
}

html,
body,
#root {
/*   height: 100%;
  width: 100%; */
 /*  overflow:hidden; */
}


.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: supria-sans,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    monospace;
}

h1 {
  font-family: bookmania, serif;
  font-weight: 1200;
  font-style: bold;
  font-size:0.7rem;
}

h2 {
  font-family: bookmania, serif;
  font-style:bold;
  font-size:0.7rem;
}

p {
  font-size: 0.6em;
}