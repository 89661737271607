:root {
  font-size: calc(8px + 2vmin);
}

.close {
  z-index:1000;
  position: fixed;
  right:0;
  top: 0;
  color: white;
  margin: 0.25rem;
  transform: scale(.8);
}

.dark {
  color: black;
}

.video-container {
  position: relative;
  width: 560px;
  height: 315px;
  margin: auto;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}



.App {
  text-align: center;
}

.BackgroundImage {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:-1000; 
  /*background: linear-gradient(rgba(255,255,255,0.65), rgba(17, 22, 145,0.25)),url(./bg.jpg);*/
  background-size: cover;
  background-postion: center center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}


p {
    font-size: 0.4em;
    line-height: 1.6em;
    max-width: 680px;
}

p.italics {

}

.App-header {
  background-color: blue;
  background: linear-gradient(rgba(255,255,255,0.65), rgba(17, 22, 145,0.25)),url(./bg.jpg);
  background-size: cover;
  background-postion: center center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

a, a:visited {
  color: darkblue;
}

a.hiddenLink {
  color:black;
  text-decoration: none;
}

a.hiddenLink:hover {
  text-decoration: underline;
}

a.learningCategory {
  font-size: 0.45rem;
  border: 1px solid darkblue;
  margin: 0.5rem;
  margin-top:0;
  padding: 0;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.18em;
  font-family: bookmania,serif;
  font-style: normal;
  color: darkblue;
  text-decoration: none;
  border-radius: 0.8em;
}

li {
  white-space: nowrap;
}

a.learningCategory:hover, a.selectedLearningCategory {
  background: darkblue;
  color:white;
  text-decoration:none;
}

footer {
  position: fixed;
  bottom:0;
  right:0;
  font-size:0.6em;
  margin:0.2em;
}

.learningsheader {
  z-index: 1000;
  /* top:0;
  position:sticky; */
  width: 100%;
}

.PortraitsHeader {
  /*background: rgba(0,0,0,0.8);*/
  color:white;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction:row;
  justify-content:space-around;
  z-index: 20;
  height: 200px;
  user-select: none;
}

.PortraitsLeft {
  color:white;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 5vw;
  display: flex;
  align-items: center;
  flex-direction:column;
  justify-content:space-around;
  z-index: 20;
  height: 100vh;
}
.PortraitsLeft img {
  width: 100%;
  filter: grayscale(100%);
}
.PortraitsLeft img:hover{
  width: 100%;
  filter: grayscale(0%);
}

figcaption {
  font-size:0.4em;
  margin-bottom:1em;
}
:root {
  font-size: 50px;
}  
video {
  width: 60vmin;
}

.MapsMedia img {
  width: 50vmin;
}

nav img {
  height:30vmin;
  margin: 0.25em;
  filter:grayscale(100%);
  /*mix-blend-mode: multiply;*/
  border-bottom:5px solid rgba(0,0,0,0);
}

.selectedParticipant img  {
  filter:grayscale(0%);
  border-top:5px solid darkblue;
}

ul {
  padding-inline-start: 0px;
  padding:0;
  margin:0;
}

.learningsFilters {
  padding-top:0vmin;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.Maps {
   padding:0;
   margin:0;
   width:100vmax;
   height:100vmin;
}

.MapsBG {
  min-height:100%;
  overflow: scroll;
}

.MapsMedia {
  display: block;
  position: absolute;
  z-index: 10;
  text-align:left;
}

.PortraitsHeader img {
  height: 100%;
}

.Maps img {
  height:100%;
}

.darkbg {
  z-index: -100;
  position: fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background:rgba(0,0,25,0.8);
  margin:0;
  padding:0;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  color:white;
}

.blankbg {
  position:fixed;
  z-index: -100;
  top:0;
  left:0;
 /*  overflow:hidden; */
  width:100vw;
  height:100vh;
  background: linear-gradient(rgba(255,255,255,0.65), rgba(100, 100, 216, 0.637));
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  color:black;
}


.whitebg {
  width:100vw;
  height:100vh;
  background: white;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  color:black;
}

.homeAgain {
  max-width: 800px;
  margin-left:auto;
  margin-right:auto;
}

.justAWhiteBG {
  background:white;
  width:100vw;
  height:100vh;
}

#film {
  margin:0 auto;
  display:block;
}

iframe {
  background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1)),grey;
  border:none;
}


#learningstitle {

    color: black;
    text-align: center;
}

.grid {
  /* position:absolute;
  bottom:0; */
width: 100%;
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
/*   padding: 25px 20px;
 */
/*   overflow-y: auto; */
 /*  height: 70vmin; */
  color:black;
  justify-content: center;
}

.learning {
  background-color: #fff;
  /*border:1px solid black;*/
  text-align:left;
  /*font-weight:bold;*/
  font-size:0.38rem;
  line-height:1.4em;
  margin: 0.5em;
  /*cursor: grab;*/
  display: inline-block;
  flex-direction: column;
  width: 210px;
  height: 270px;
  padding: 15px;
  position: relative;

  /*user-select: none;*/
}

.learningFootnote {

  font-size:0.6em;
  line-height: 1.2em;
   position:absolute;
  bottom:1em;
 padding-right:2em; 
}

.FilmHeader {
  color: white;
  margin-top: 15%;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  :root {
    font-size: 25px;
  } 

  h1 {
    font-size: 2rem;
    line-height:2rem;
  }
  p {

    font-size: 5vw;
    line-height: 1.5em;
  }
  
  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }

  .homeAgain {
  margin-top: 5vmin;
  max-width: 94%;
}

.blankbg {
  justify-content: flex-start;
}

  #learningstitle {
    margin-top: 1.4em;
    font-size: 9vw;
    line-height: 9vw;
  }
  a.learningCategory {
    font-size:.7rem;
  }

  .learning {
    font-size: 7vw;
    line-height: 9vw;
    width: 100%;
    height:0;
    padding-bottom: 50%;
    margin-top:0;
  }

  .grid {
    -webkit-overflow-scrolling: touch;
  }

  .FilmHeader {
    color: white;
    margin-top: 30%;
    text-align: center;
    font-size: 10vw;
  }
}